import React from 'react';
import './PageContent.scss';
import { v4 as uuidv4 } from 'uuid';

class PageContent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      content: props.content,
    };
    console.log(props);
  }

  render() {
    return (
      <div className="slider-item" key={this.props?.keyChild || uuidv4()}>
        <div className="slide-image">
          <img
            src={this.state?.content?.img}
            className="slide-item__image"
            alt="Project Image"
          />
        </div>

        <div className="slide-content">
          <div className="slide-content-inner">
            <div className="item-cat">{this.state?.content?.title}</div>
            <h2 className="item-title" data-splitting>
              {this.state?.content?.description}
            </h2>
            <a
              href={this.state?.content?.linkUrl}
              className="item-link link-underline cursor-link"
            >
              {this.state?.content?.link}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PageContent;
