import React from 'react';
import './SideContent.scss';

class SideContent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  closeSideContent = (): void => {
    const { sideContent } = this.state;
    sideContent.isSideMenuVisible = false;
    this.setState({ sideContent: sideContent });
  };

  openSideContent = (): void => {
    const { sideContent } = this.state;
    sideContent.isSideMenuVisible = true;
    this.setState({ sideContent: sideContent });
  };

  render() {
    return (
      <div
        data-content={'side-content'}
        id="side-content"
        className={
          this.state.sideContent.isSideMenuVisible
            ? 'animate__animated animate__slideInRight'
            : 'animate__animated animate__slideOutRight'
        }
      >
        <div className="close" onClick={this.closeSideContent}>
          <div className="self-close">X</div>
        </div>
        <div className="title">{this.state.sideContent.title}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: this.state.sideContent.content }}
        />
      </div>
    );
  }
}

export default SideContent;
