import './App.scss';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Home from './Pages/Home/Home';

const App = () => {
  const routes = useRoutes([{ path: '/', element: <Home /> }]);
  return routes;
};

const AppWrapper = () => {
  return (
    <div className="app">
      <Router>
        <App />
      </Router>
    </div>
  );
};

export default AppWrapper;
