import React from 'react';
import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="header">
        <header>
          <div className="logo-container row">
            <div className="col">
              <img className="logo" src="/images/white_logo.png" alt="" />
            </div>
            <div className="col m-auto p-0">Universum</div>
          </div>

          <div className="menu-burger">
            <div className="parallax-wrap burger-wrap">
              <div className="parallax-icon">
                <div className="burger-box">
                  <div className="burger-inner"></div>
                </div>
              </div>
            </div>
            <div className="burger-text">
              <div className="text-menu">MENU</div>
              <div className="text-open">OPEN</div>
              <div className="text-close">CLOSE</div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
