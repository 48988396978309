import React from 'react';
import './Menu.scss';

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="menu-overlay">
        <div className="container">
          <div className="content-wrapper">
            <nav className="primary-nav">
              <ul>
                <li>
                  <a
                    href="/"
                    className="menu-link cursor-link"
                    data-type="page-transition"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="menu-link cursor-link"
                    data-type="page-transition"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="menu-link cursor-link"
                    data-type="page-transition"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
            <div className="company-info">
              <a href="#" className="info-email link-underline cursor-link">
                contact@universumcode.com
              </a>
              <br />
              <a href="#" className="info-tel link-underline cursor-link">
                (678) 813-1548
              </a>
              <div className="info-address">
                <p>Atlanta, GA.</p>
              </div>
              <ul className="sm-links">
                <li>
                  <a href="#" className="cursor-link">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="cursor-link">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="cursor-link">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="cursor-link">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
