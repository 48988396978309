import Header from './../../Components/Header/Header';
import Menu from './../../Components/Menu/Menu';
import React from 'react';
import PageContent from '../PageContent/PageContent';
import './Home.scss';
import SERVICES from '../../Data/services.json';
import { v4 as uuidv4 } from 'uuid';
import SideContent from '../../Components/SideContent/SideContent';
class Home extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      sideContentRef: React.createRef(),
      sideContent: {
        isSideMenuActivated: false,
        isSideMenuVisible: false,
        title: null,
        content: null,
      },
    };
    this.setSideContent.bind(this);
    this.handleClickOutsideSideContent =
      this.handleClickOutsideSideContent.bind(this);
  }

  componentDidMount() {
    /**
     * Activating side content box after page loads
     * to avoid show the component during the loading.
     */
    setTimeout(() => {
      const { sideContent } = this.state;
      sideContent.isSideMenuActivated = true;
      this.setState({ sideContent: sideContent });
    }, 2000);

    /**
     * Enabling detection of handleClickOutsideSideContent
     */
    document.addEventListener('mousedown', this.handleClickOutsideSideContent);
  }

  componentWillUnmount() {
    /**
     * Disabling detection of handleClickOutsideSideContent
     */
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideSideContent
    );
  }

  setSideContent = (service) => {
    const { sideContent } = this.state;
    sideContent.title = service.title;
    sideContent.content = service.description;
    sideContent.isSideMenuVisible = true;
    this.setState({ sideContent: sideContent });
    this.state.sideContentRef.current.openSideContent();
  };

  handleClickOutsideSideContent = (event) => {
    const currentElement = event?.target?.dataset?.content;
    const parentElement = event?.target?.offsetParent?.dataset?.content;

    event && (currentElement || parentElement)
      ? null
      : this.state.sideContentRef.current.closeSideContent();
  };

  render() {
    return (
      <div id="main">
        <div className="ct-main-content ct-index">
          <SideContent
            sideContent={this.state.sideContent}
            ref={this.state.sideContentRef}
          />
          <Header />
          <div id="root"></div>
          <Menu />
          <div id="scroll-content"></div>
          <div className="page-content">
            <div id="pixi-slider">
              <div className="slider-wrapper">
                <div className="slider-item col-10">
                  <div className="slide-image">
                    <img
                      src="images/projects/tech-universe/01.jpg"
                      className="slide-item__image"
                      alt="Project Image"
                    />
                  </div>
                  <div className="slide-content">
                    <div className="slide-content-inner">
                      <div className="row">
                        {SERVICES &&
                          SERVICES.map((service) => (
                            <div
                              className="col-12 col-sm-6 col-md-3 service-effect animate__animated animate__zoomInDown"
                              key={uuidv4()}
                            >
                              <h3 className="service-title">{service.title}</h3>
                              <div className="service-description">
                                {service.description}
                              </div>
                              <div className="link">
                                <a
                                  href={void 0}
                                  onClick={() => this.setSideContent(service)}
                                  className="item-link link-underline cursor-link"
                                >
                                  {service.link}
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                {SERVICES &&
                  SERVICES.map((service) => (
                    <PageContent
                      content={service}
                      key={uuidv4()}
                      keyChild={uuidv4()}
                    />
                  ))}
              </div>

              <div className="arrows-wrap">
                <div
                  className="slider-nav slider-nav--prev parallax-wrap"
                  data-nav="previous"
                >
                  <div className="parallax-icon">
                    <i className="fas fa-chevron-left"></i>
                  </div>
                </div>
                <div
                  className="slider-nav slider-nav--next parallax-wrap"
                  data-nav="next"
                >
                  <div className="parallax-icon">
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
                <div className="progress-bar">
                  <div className="progress"></div>
                </div>
              </div>
              {/* <!--PIXI SLIDER NAVIGATION END--> */}

              <div id="canvas-container">
                <div id="particles-js"></div>
              </div>
            </div>
            {/* <!--PIXI SLIDER END--> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
